import React from "react";
import "./Services.scss";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import sportsCalender2025 from "../../assets/docs/RB_Sports Calendar_2025.pdf"

const Services = () => {
  return (
    <>
      <Header />
      <section className="services">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Services</p>
        </div>
      </section>

      <div id="header-img-wrapper" className="d-flex sp">
        <div className="img header-img"></div>
        <div className="services">
          <h3 id="font-size-heading-32">
            Embark on an Extraordinary Journey,
            <br />
            Elevating Your Experience to New Frontiers.
          </h3>

          <p id="font-size-text-16">
            Welcome to RedBeryl's exquisite collection of services designed to
            elevate your lifestyle and create unforgettable experiences.
          </p>

          <p id="font-size-text-16">
            Discover our pillars, each crafted to fulfill your desires and
            provide you access to the world of luxury like never before.
          </p>

          <p id="font-size-text-16">
            From mobility to fine dining, sports and entertainment to high-end
            retail and art, our pillars are the foundation of a life redefined
            by elegance, exclusivity, and indulgence.
          </p>
        </div>
      </div>

      <section id="services-cards-wrapper" className="services">
        <hr className="c-red" />

        <div id="gap" className="grid-container">
          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_Dedicated%20RM.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_Dedicated%20RM.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                DEDICATED RELATIONSHIP MANAGER
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Your dedicated relationship manager provides personalised and
                perceptive support, ensuring your needs are met with precision
                and care, making every interaction a delightful experience.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_24-7%20Concierge.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_24-7%20Concierge.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                24/7 CONCIERGE
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Enjoy seamless luxury with personal concierge® services across
                40+ countries, ready to fulfil your every request with
                unwavering dedication and utmost precision, making every moment
                an unrivalled experience.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_Dream%20weaver.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_Dream%20weaver.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                DREAM WEAVER
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Let our skillful Dream Weavers tailor a tapestry of your
                desires, meticulously crafting your personalised RedBerylTM
                list, where dreams are transformed into tangible realities, and
                each experience becomes a masterpiece, designed exclusively for
                you.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_Sports,%20events.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_Sports,%20events.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                SPORTS, EVENTS & ENTERTAINMENT
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Enjoy a myriad of extraordinary events and meet-ups, with
                privileged access to coveted gatherings, celebrity encounters,
                art soirées, and wine tastings, immersing you in a world of
                enchantment.
              </p>
              <a className="download-btn"  href={sportsCalender2025} download>Get Sports Events Schedule</a>
            <a className="download-btn"  href={sportsCalender2025} download>Get Concerts / Music Festivals Schedule</a>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_Curated%20journeys.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_Curated%20journeys.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                CURATED JOURNEYS
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Embark on extraordinary journeys, adorned with exclusive member
                benefits, access to over 15,000 hotels worldwide and captivating
                itineraries tailored to your preferences.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_Finest%20dining.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_Finest%20dining.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                FINEST DINING
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Indulge in an exceptional culinary journey, savouring delectable
                delights from the finest, where celebrated chefs and artisans
                craft a symphony of flavours, textures, and aromas, elevating
                each moment.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_VIP%20Airport%20assist.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_VIP%20Airport%20assist.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                VIP AIRPORT ASSIST
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Elevate your travel with our airport assistance services,
                spanning the globe for seamless journeys; our dedicated team
                provides VIP meet and greet, whisking you through check-in,
                security, and customs.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_Elite%20mobility.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_Elite%20mobility.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                ELITE MOBILITY
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Experience the epitome of exclusivity and indulgence as you
                traverse the world, where luxury cars, limousines, private jets,
                and yachts await to transport you to your destinations, ensuring
                every moment is an opulent celebration of sophistication and
                refinement.
              </p>
            </div>
          </div>

          <div className="slider">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/services/RB_Services_exclusive%20clubs.webp"
              alt="https://redberyl.co/images%20only/services/RB_Services_exclusive%20clubs.webp"
            />
            <div className="desc">
              <h4 id="font-size-special-18" className="font-f37">
                EXCLUSIVE CLUBS
              </h4>
              <hr className="c-red" />

              <p id="font-size-text-16">
                Enter an exclusive world of super-premium, members-only
                nightclubs and social clubs worldwide, where you'll step into a
                realm of glamour and prestige, mingling with like-minded
                individuals making each night an unforgettable celebration.
              </p>
            </div>
          </div>
        </div>
        <div className="cta">
          <a href="/memberships">
            <button className="btn-community">Apply Now</button>
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Services;
